import React from "react"

import Layout from "../../components/layout"
import * as styles from '../../styles/pages/contact-form.module.css'

import ContactForm from "../../components/contactform"


const ContactDealerPage = () => (
  <Layout mobileTitle={true} activePath='contact' fullPath='contact/dealer' pageTitle="Locate a Dealer">
  	<div className={styles.formholder}>
	    <div className={styles.forminfo+" text-centered"}>
	    	Find the nearest Accredited BOYSEN<span className='registeredicon'>&reg;</span> Dealer to get your supply of Konstrukt&trade; Products.<br/>Our dealers are also accepting special orders.
	    </div>
	    <br/><br/>
		<ContactForm showMessage={false} mobileDisplay={true} showLabel={false}/>
	    <div className={styles.forminfo}>
	    	NOTE:<br/>
	    	To serve you best, we will email you the dealers closest to your address.
	    </div>
	</div>
  </Layout>
)

export default ContactDealerPage
